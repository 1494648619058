import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyBB288Hs-rhixZIaZQW7tep0sFrTb2HgJI",
  authDomain: "viogen.firebaseapp.com",
  projectId: "viogen",
  storageBucket: "viogen.appspot.com",
  messagingSenderId: "744868529196",
  appId: "1:744868529196:web:f708363e3b86b1f5c5bd89",
  measurementId: "G-MJEVYLF4FL"
};

function Cookie() {

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      console.log("Consent Cookie found! Using google analytics")
      handleAcceptCookie();
    }
  }, []);

  const handleAcceptCookie = () => {
    const firebaseApp = initializeApp(firebaseConfig);
    const analytics = getAnalytics(firebaseApp);
    console.log(analytics);
    console.log("Cookies accepted!");
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_ga_MJEVYLF4FL");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    console.log("Cookies declined!");
  };

  return (
    <CookieConsent expires={180}
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}

      enableDeclineButton
      style={{ opacity: '0.8', 'fontSize': '0.7rem' }}
      buttonStyle={{
        'boxShadow': '0.3rem 0.3rem #3749C8',
        'border': '2px solid black',
        'backgroundColor': '#FFC2CF'
      }}
      declineButtonStyle={{
        'boxShadow': '0.3rem 0.3rem #3749C8',
        'border': '2px solid black',
      }}
      buttonClasses="red"
      buttonText="✓ Akzeptieren"
      declineButtonText="✗ Ablehnen"
    >🍪 Diese Webseite nutzt Cooies (google analytics). Siehe unsere <a className="underline" href="#privacy">Datenschutzvereinbarung</a> 🍪</CookieConsent>
  )
}

export default Cookie;