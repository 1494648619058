function Impressum2() {
    return (<section className="mb-16 text-s">
        <h1 className="font-bold text-xl">Impressum</h1>
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>Kevin German &amp; Marius Müller<br />
          Zähringeralle 27<br />
          75177 Pforzheim</p>
        <p><strong>Vertreten durch:</strong><br />
          Kevin German &amp; Marius Müller</p>
        <h2 className="text-bold">Kontakt</h2>
        <p>E-Mail: admin@vyogen.de</p>
        <h2>EU-Streitschlichtung</h2>
        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
        <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>

    </section >)
}

export default Impressum2;