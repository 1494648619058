import Item from './Items.js';
import products from './products.json';

function Store() {
    return (
        <section id="store" className="h-auto relative container mx-auto px-6 font-mono mb-32">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h2 className="text-xl lg:text-4xl">Coming soon...</h2>
            </div>
            <div className="relative block z-20  opacity-20 ">
                <div className="w-full lg:w-1/2">
                    <h2 className="mb-2 text-2xl">Molecules</h2>
                    <p className="text-md lg:text-xs font-light mb-8">
                        Not for human consumption
                </p>
                </div>

                <div className="flex flex-wrap items-center mx-auto justify-center lg:-m-6 lg:justify-between">
                    {products.map(p => <Item
                        key={p.id}
                        name={p.name}
                        description={p.shortDescription}
                        price={p.price}
                        image={p.image}
                    ></Item>)}
                </div>
            </div>

        </section>
    )
}

export default Store;