import SupplyChain from './SupplyChain.js';
import logo from './undraw_team_chat_re_vbq1.svg';

function About() {
    return (
        <div className="mb-16">
            <section id="about" className="items-baseline h-auto flex flex-wrap justify-between container mx-auto px-6 font-mono">
                <div className="lg:mb-auto  w-full lg:w-1/2" >
                    <h2 className="mb-6 text-2xl">Unsere Mission</h2>
                    <p className="text-md lg:text-s font-light mb-8">
                        Der Fortschritt der letzten Jahre im Bereich der Informationstechnologie
                        – allen voran der künstlichen Intelligenz – bietet Gemeinden zahlreiche neue Potentiale
                        für die Kommunikation zwischen Gemeinde und Bürger.
                        Wir sind davon überzeugt das durch die Verwendung dieser Technologien,
                        positive Verbesserungen in der Kommunikation und Partizipation erreicht werden können.
                    </p>
                </div>
                <img className="h-0 lg:h-auto py-8 relative w-1/2 lg:w-1/3 lg:pr-16" src={logo} alt="Darstellung von Mails mit Personen" />
            </section>
            <SupplyChain />
        </div>
    );
}


export default About;