function Notification({ setHtml, title, text }) {
    return (
        <div className="hard-shadow bg-beige-600 fixed bottom-10 left-1/2 transform -translate-x-1/2 flex opacity-80 notification transition-opacity">
            <div className="border-black border-2 p-3 shadow-lg">
                <div className="flex flex-row">
                    <div className="px-2">
                    </div>
                    <div className="ml-2 mr-6">
                        <span className="font-semibold">{title}</span>
                        {setHtml !== null ?
                            <span className="block text-black" dangerouslySetInnerHTML={{ __html: setHtml }}></span>
                            :
                            <span className="block text-black">{text}</span>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Notification;