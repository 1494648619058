import MailchimpSubscribe from "react-mailchimp-subscribe"
import { useState } from 'react';

function Form({ status, message, onValidated }) {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
            email.indexOf("@") > -1 &&
            onValidated({
                MERGE0: email,
            });
    }

    console.log("Subscription Status: ", status, message);
    return (<form onSubmit={(e) => handleSubmit(e)} className="bg-beige-dark border-2 border-black p-8 hard-shadow-light">

        <div className="mb-6">
            <label htmlFor="email" className="mb-3 block">
                Email-Addresse</label>
            <input onChange={e => setEmail(e.target.value)} value={email}
                type="email" id="email" className="bg-white border-black border p-3  w-full" placeholder="karl.heinz@placeholder.com" required></input>
        </div>
        <button type="submit"
            className={"hard-shadow border-black  border-2  py-3 px-12 mr-5 text-lg w-full active:hard-shadow-light " +
                (status === "success" ? "bg-green-500 hover:bg-green-300" :
                    (status === "sending" ? "bg-yellow-500 hover:bg-yellow-300" :
                        (status === "error" ? "bg-red-500 hover:bg-red-300" : "bg-pink hover:bg-beige")))}>

            {status === "success"
                ? "Erfolg!"
                : status === "sending"
                    ? "Sende..."
                    : status === "error"
                        ? "Fehler!"
                        : "Newsletter abonnieren"
            }
        </button>
        <div className="align-middle mt-4 -mb-4">
            <input type="checkbox" className="mr-2" required></input>
            <span className="text-xs  text-right">
                Akzeptiere unsere <a className="underline" href="#privacy">Datenschutzvereinbarung</a>.
            </span>
        </div>
        {status === "error" ?
            <div className="align-middle mt-4 -mb-4">
                <span className="text-xs  text-right text-red-500" dangerouslySetInnerHTML={{ __html: message }}>
                </span>
            </div> : null
        }
    </form>)
}

function Subscribe() {
    const postUrl = `${process.env.REACT_APP_MAILCHIMP_URL}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
    return (
        <div>
            <MailchimpSubscribe
                url={postUrl}
                render={
                    ({ subscribe, status, message }) =>
                        <Form
                            onValidated={formData => subscribe(formData)}
                            status={status}
                            message={message}>
                        </Form>
                }
            ></MailchimpSubscribe>
        </div>
    )
}

export default Subscribe;