import { useState } from 'react';
import Privacy from './Privacy';
import Impressum from './Impressum';
import logo from "./undraw_city_life_gnpr.svg"

function Legal() {
    const [activeImpressum, setImpressum] = useState();
    let text = "Zeige Impressum & Datenschutzvereinbarung"
    if (activeImpressum) {
        text = "Verstecke Impressum & Datenschutzvereinbarung"
    }

    return (
        <div id="privacy" className="h-auto container mx-auto pb-2 px-6 font-mono items-center relative">
            <h1 className="hover:bg-green-300 w-auto max-w-xl text-xl border-2 border-black -mt-6 mb-6 p-2 hard-shadow" onClick={() => setImpressum(!activeImpressum)}>{text}</h1>
            {activeImpressum ?
                <div>
                    <Impressum></Impressum>
                    <Privacy></Privacy>
                </div>
                : null
            }
            <img className="h-0 lg:h-auto lg:mb-0 absolute pb-8 bottom-0 right-0 lg:w-1/2 " src={logo} alt="" ></img>
        </div>

    )
}

export default Legal;