import React, { useState } from 'react';
import Notification from '../Notification/Notification.js';

function Header() {
  const [activeNotification, setNotification] = useState(false);
  let timer = null;

  const pushNotification = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setNotification(false);
    }, 5000);
    setNotification(true);
  }

  return (
    <header className="h-0 lg:h-auto  mx-auto opacity-85 bg-beige-600 sticky top-0 z-50 flex flex-wrap">
      {activeNotification ? <Notification title="Currently not available" text="Will be ready when the shop opens"></Notification> : null}
      <nav className="flex w-screen justify-between">
        <div className="px-5 xl:px-12 py-4 flex w-full">
          <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
            <li><a className="hover:text-pink" href="#hero">Vyogen</a></li>
            <li><a className="hover:text-pink" href="#about">Unsere Mission</a></li>
            <li><a className="hover:text-pink" href="#contact">Kontakt</a></li>
          </ul>

        </div>
      </nav>
    </header>
  )
}

export default Header;