import Subscribe from './Subscribe';

function Hero() {
    return (
        <section id="hero" className="h-partial my-16 flex font-mono items-center">
            <div className="container mx-auto px-6">
                <div className="w-full lg:flex items-center relative z-10">
                    <div className="w-full lg:w-1/2">
                        <h2 className="text-md lg:text-2xl">
                            Innovation für Deutschlands Gemeinden
                        </h2>
                        <div className="mb-2 mt-4 lg:mb-6">
                            <img alt="Vyogen" src="vyogen.png" className="object-contain h-20"></img>
                        </div>
                        <p className="text-md lg:text-xl font-light mb-4">
                            KI basierte Sprachverarbeitung für Prozessautomatisierung und Partizipation in Gemeinden.
                        </p>

                    </div>

                    <div className="w-full lg:w-1/2 lg:pl-24">
                        <Subscribe></Subscribe>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;