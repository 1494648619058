import Header from './Components/Header/Header.js';
import Store from './Components/Store/Store.js';
import Hero from './Components/Hero/Hero.js';
import About from './Components/About/About.js';
import Reviews from './Components/Reviews/Reviews.js';
import Contact from './Components/Contact/Contact.js';
import Legal from './Components/Legal/Legal.js';
import Cookie from './Components/Cookie/Cookie';


function App() {
  const consoleStyle = [
    'color: black',
    'background: #faddc8',
    'font-size: 15px',
    'border: 1px solid black',
    'box-shadow: 1.5rem 1.5rem #3749C8',
    'padding: 10px',
  ].join(';');
  
  console.log("%cGreetings stranger, nice to see you here! Hopefully you won't find anything here (...yet). Kind regards from your Vyogen Team 😊 ", consoleStyle);

  console.log("%cPS: It would be nice and mega sweet if you could sign up for the newsletter, so we can estimate the sizes of the first batches. Thank you very much!", consoleStyle);

  return (
    <main className="bg-gradient-to-b from-beige via-beige-dark to-pink">
      <Header></Header>
      <Hero></Hero>
      <About></About>
      <Contact></Contact>
      <Legal></Legal>
      <Cookie></Cookie>
      </main>
  );
}

export default App;
