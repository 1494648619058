import logo from './undraw_chat_bot_re_e2gj (2).svg';
import logo2 from './undraw_team_chat_re_vbq1.svg';

function SupplyChain() {
    return (
        <section id="about" className="h-auto flex flex-wrap container mx-auto px-6 font-mono items-center lg:justify-between justify-center">
            <img className="mb-16 lg:mb-0 relative w-full lg:w-1/2 lg:pr-24" src={logo} alt=""></img>
            <div className="w-full lg:w-1/2">
                <h2 className="mb-6 text-2xl">Unsere Leistung</h2>
                <p className="text-md lg:text-s font-light">
                    Aufbauend auf dem aktuellsten Stand der Wissenschaft im
                    Bereich der KI basierten Sprachverarbeitung, bieten wir
                    unterschiedliche Leistungen an.
                    <ol>
                        <li>Durch ein KI basiertes Email Assistenzsystem ermögliche wir die
                            automatische Klassifikation, Weiterleitung und Beantwortung von
                            eintreffenden Bürgeranfragen.
                        </li>
                        <li>
                            Durch einen Bürgerassistenten für die gängigsten Messenger-Dienste Deutschlands,
                            bieten wir ein niedrigschwelliges Werkzeug für Bürgerpartizipation und Kommunikation.
                        </li>
                    </ol>
                </p>
            </div>
            <img className="lg:h-0 mt-16 lg:mb-0 relative w-full lg:w-1/2 lg:pr-24" src={logo2} alt=""></img>

        </section>
    );
}


export default SupplyChain;