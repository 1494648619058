function Contact() {
    return (
        <section id="contact" className="h-96 flex flex-wrap container mx-auto px-6 font-mono items-center">
            <div className="w-full lg:w-1/2">
                <h2 className="mb-6 text-2xl">Kontaktiere uns</h2>
                <p className="text-md lg:text-s font-light mb-8">
                Du bist herzlich eingeladen uns zu kontaktieren :) 
               </p>
               <p className="text-md lg:text-x font-light mb-8">
                marius@vyogen.de
               </p>
            </div>
        </section>
    );
}


export default Contact;